import {
  Box, Button, Card, CardBody, CardFooter, Heading, Image,
  Main,
  ResponsiveContext, Text
} from "grommet";
import React from "react";
import styled from "styled-components";
import LoadingView from "../components/loading";
import TransInfo from "../components/trans-info";
import SiteLayout from "../layout";
import { enqiry } from "../services/helpers";
export default function ReferenceSuccess(props) {
  const [loading, setLoading] = React.useState(false);
  const [success, setSuccess] = React.useState(false);
  const [mgs, setMgs] = React.useState({});
  const [upg, setUpg] = React.useState({});
  const [trans, setTrans] = React.useState({});
  const [merchant, setMerchant] = React.useState({});
  const size = React.useContext(ResponsiveContext);
  let transId = props.location.pathname.replace("/reference-success/", "");
  transId = transId.replace("/", "");
  const MINUTE_MS = 10000;

  React.useEffect(() => {
    async function fetchMyAPI() {
      try {
        let response = await enqiry(transId);
        if (response.data.status === 200) {
          if (response.data.data.transaction.status === "COMPLETED") {
            window.location = "/t/" + transId;
          }
          setMgs(response.data.data.mgs);
          setUpg(response.data.data.upg);
          setTrans(response.data.data.transaction);
          setMerchant(response.data.data.merchant);
        } else {
          window.location = "/error?e=" + response.data.error;
        }

        setLoading(false);
      } catch (e) {
        setLoading(false);
        window.location = "/error?e=" + e.message;
      }
    }

    const interval = setInterval(() => {
      if (trans?.status === "COMPLETED") {
        window.location = "/t/" + transId;
      } else {
        fetchMyAPI();
      }
    }, MINUTE_MS);

    fetchMyAPI();
    return () => clearInterval(interval);
  }, [transId]);
  return (
    <SiteLayout>
      {loading && <LoadingView />}
      {!loading && (
        <WrapConontroller>
          <Main direction="row">
            <Box
              width={size === "small" ? "100%" : "580px"}
              pad={{ left: "medium" }}
            >
              <Box justify="center" align="start" pad="small" height="80px">
                <Image src={require("../images/logo.svg")} />
              </Box>
              <TransInfo trans={trans} merchant={merchant} />
              <Card pad="small" background="light-1" gap="medium">
                <CardBody>
                  {trans?.status === "ACTIVE" && (
                    <>
                      <Heading
                        level="4"
                        color="status-warning"
                        textAlign="left"
                      >
                        Pending Payment
                      </Heading>
                      <Heading level="4" color="brand" textAlign="left">
                        Reference Code : # {trans.reference} (Opay)
                      </Heading>
                      Your order status is pending, please click refresh button
                      to query the order status. It may takes a long time to
                      process, you can contact support team or cancel it.
                    </>
                  )}
                  {trans?.status === "COMPLETED" && (
                    <>
                      <Heading level="4" color="status-ok" textAlign="left">
                        Success Payment
                      </Heading>
                      Your order status is completed
                    </>
                  )}
                </CardBody>
                <CardFooter>
                  {trans?.status === "ACTIVE" && (
                    <>
                      <Button
                        margin={{ vertical: "small" }}
                        primary
                        disabled={loading}
                        size="medium"
                        label={<Text color="white">Refresh</Text>}
                        onClick={() => {
                          window.location = "/wallet-success/" + trans.uid;
                        }}
                      />
                      <Button
                        margin={{ vertical: "small" }}
                        secondary
                        disabled={loading}
                        size="medium"
                        label={<Text color="brand">Try Another Option</Text>}
                        onClick={() => {
                          window.location = "/t/" + trans.uid;
                        }}
                      />
                    </>
                  )}
                </CardFooter>
              </Card>
            </Box>
            {size !== "small" && (
              <Box flex={{ grow: 2 }} className="controlDiv">
                <Image
                  src={require("../images/elipse-background.svg")}
                  className="controlElipse"
                />
                <Box
                  className="controlIcon"
                  fill
                  justify="center"
                  align="center"
                >
                  <Image src={require("../images/mobile-payment.svg")} />
                </Box>
              </Box>
            )}
          </Main>
        </WrapConontroller>
      )}
    </SiteLayout>
  );
}

const WrapConontroller = styled.div`
  min-height: 100vh;
  .controlDiv {
    position: relative;
    overflow: hidden;
    .controlElipse {
      position: absolute;
      width: calc(100vh + 1000px);
      height: calc(100vh + 1000px);
      bottom: -250px;
      left: 10px;
      max-width: initial;
      z-index: 100;
    }
    .controlIcon {
      position: absolute;
      z-index: 101;
      left: 150px;
      min-width: 300px;
    }
  }
`;
